import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { map, Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { MeldingenService } from './profiel/service/meldingen.service';
import { VerhaalService } from './verhaal/verhaal-shared/service/verhaal.service';
import { ParkourCookiesService } from './shared/services/parkour-cookies.service';
import { isNativeApp } from './utils';
import { A11yConfig } from './core/config/a11y-config';
import AuthService from './authentication/service/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'parkour-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  subscriptions: Subscription[] = [];
  authenticated = false;
  showGlobalHeader = !Capacitor.isNativePlatform();

  protected readonly environment = environment;
  protected readonly isNativeApp = isNativeApp;
  protected readonly user$ = this.authService.user$;
  protected readonly detailedUser$ = this.authService.detailedUser$;
  protected readonly canGoBack$ = this.activatedRoute.queryParamMap.pipe(
    map((queryParamMap) => queryParamMap.get('noBack') !== 'true'),
  );

  protected currentUrl = '';
  protected currentContextId = '';

  constructor(
    private readonly a11yConfig: A11yConfig,
    private readonly authService: AuthService,
    private readonly meldingenService: MeldingenService,
    private readonly verhalenService: VerhaalService,
    protected readonly parkourCookiesService: ParkourCookiesService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    SplashScreen.hide().then();
  }

  async ngOnInit() {
    this.meldingenService.fetchOngelezenMeldingen();
    this.verhalenService.startFetchingJeugdhulpHistoriekGebeurtenissen();
    this.parkourCookiesService.checkCookiePermissions();

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url != '') {
          this.currentUrl = this.stripHash(e.url);

          const segments = this.currentUrl.split('/');

          if (segments.length >= 3) {
            this.currentContextId = segments[2];
          }
        } else {
          this.currentUrl = '';
        }
      }
    });
  }

  ngAfterViewInit() {
    this.a11yConfig.config();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  stripHash(url: string): string {
    const urlObject = new URL(url, 'http://localhost');
    return `${urlObject.pathname}${urlObject.search}`;
  }
}
