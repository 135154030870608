import { Component, Input } from "@angular/core";
import {ParkourInfoCardComponent, TParkourIconName} from "@parkour/ui";

@Component({
  selector: "parkour-info-card-with-title",
  templateUrl: "./parkour-info-card-with-title.component.html",
  standalone: true,
  imports: [
    ParkourInfoCardComponent
  ],
})
export class ParkourInfoCardWithTitleComponent {
  @Input() public icon: TParkourIconName = "info-circle";
  @Input() public style: string = "bg-n-300";
  @Input({required: true}) public title!: string;
  @Input({required: true}) public description!: string;
  @Input() public descriptionAsHtml: boolean = false;
}
