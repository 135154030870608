<parkour-info-card [icon]="icon" [style]="style">
    <div class="flex flex-col gap-y-2">
        <p class="typo-body variant-body-fat">{{ title }}</p>

        @if (descriptionAsHtml) {
            <p [innerHTML]="description"></p>
        } @else {
            <p>{{ description }}</p>
        }

        <ng-content></ng-content>
    </div>
</parkour-info-card>