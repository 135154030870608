import { Routes } from '@angular/router';
import { VerhaalTeamlidPage } from './page/verhaal-teamlid-page/verhaal-teamlid.page';
import { GebeurtenisDetailsTeamlidPage } from './page/gebeurtenis-details-teamlid-page/gebeurtenis-details-teamlid-page';
import { specificHulplijnRoute } from '../../watwat/routes';
import { GebeurtenisSuggestieAddPage } from './page/gebeurtenis-suggestie-add-page/gebeurtenis-suggestie-add.page';

export const verhaalTeamlidRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: VerhaalTeamlidPage,
    data: { tabRoot: true },
  },
  {
    path: 'suggereer',
    component: GebeurtenisSuggestieAddPage,
  },
  {
    path: ':type/:gebeurtenis-id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: GebeurtenisDetailsTeamlidPage,
      },
      specificHulplijnRoute,
    ],
  },
];
